<template>
	<v-container>
		<v-data-table
			:loading="logsLoading"
			loading-text="Записи загружаются..."
			:items-per-page="items_per_page"
			:page="page"
			:items="items"
			:headers="headers"
			disable-sort
			hide-default-footer
		>
			<template #item.at="{item: {at}}">
				{{ moment(at).format('YYYY-MM-DD HH:mm') }}
      </template>
      <template #item.target_id="{item}">
        <router-link
            v-if="item.target == 'product'"
            :to="`/catalog/structure/product/${item.target_id}`"
            target="_blank"
        >{{ item.name }}</router-link>
        <router-link
            v-else-if="item.target == 'category'"
            :to="`/catalog/structure/${item.target_id}`"
            target="_blank"
        >{{ item.name }}</router-link>
        <span v-else-if="item.target == 'brand'">
          {{ item.name }}
        </span>
      </template>

      <template #footer>
				<v-divider class="my-3"></v-divider>
				<v-pagination v-if="!logsLoading" v-model="page" :length="total_pages"></v-pagination>
			</template>
		</v-data-table>

	</v-container>
</template>

<script>
import api from "@/services/api";
import moment from "moment";

export default {
	name: "Export",
	data: () => ({
		logsLoading: true,
		page: 1,
		total_pages: 1,
		items_per_page: 50,
		items: [],
		headers: [
			{
				text: 'Дата',
				value: 'at',
				align: 'center',
				width: '145px'
			},
			{
				text: 'Объект',
				value: 'target_id'
			},
			{
				text: 'Описание',
				value: 'text'
			}
		]
	}),
	computed: {
		'moment'() {
			return  moment
		}
	},
	mounted() {
		this.loadLogs()
	},
	methods: {
		loadLogs() {
			api.get('/export/1c-logs/', {
				params: {
					page: this.page,
					items_per_page: this.items_per_page
				}
			}).then(r => {
				this.items = r.data.results
				this.total_pages = Math.ceil(r.data.count / this.items_per_page)
				this.logsLoading = false
			})
		}
	},
	watch: {
		page() {
			this.loadLogs()
		}
	}
}
</script>

<style scoped>

</style>