<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

    <component :is="JComponent"></component>
  </v-container>
</template>

<script>
import * as routes from "@/router/routes";
import * as journal from '@/views/logs/index'

export default {
  name: "Journal",
  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Логи',
          disabled: false,
          href: this.$router.resolve({name: routes.logsIndex}).href
        },
        {
          text: this.$route.meta.title,
          disabled: true
        }
      ]
    },
    JComponent() {
      return journal[this.$route.meta.journal]
    }
  }
}
</script>

<style scoped>

</style>