<template>
	<v-container>
		<v-card flat max-width="700" class="mx-auto">
			<v-card-title>Выберите журнал</v-card-title>
			<v-card-text>
				<v-list>
					<v-list-item link :to="{ name: journal.link }" v-for="journal in journals" :key="journal.link">
						<v-list-item-icon>
							<v-icon>mdi-text</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							{{ journal.name }}
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import {routes} from "@/router";

export default {
	name: "MainView",
	data: () => ({
		journals: [
			{
				name: 'Ошибки экспорта в 1С',
				link: routes.logs1CExport
			},
		]
	})
}
</script>

<style scoped>

</style>